<template>
  <div class="d-flex flex-column align-items-center">
    <div class="mb-10 col-xs-12 col-md-8 shadowless d-flex flex-column align-items-center">
      <h1 class="text-center font-weight-bolder">Messages</h1>
      <p v-if="hasMessages" class="text-center">
        These are all the messages that will be dispatched to your nominated recipients once your account is unlocked.
        Please make sure that all the messages you want to dispath are saved as ready.
      </p>
      <p v-else class="text-center">
        Hi <b>{{ currentUser.display_name }}</b
        >. This is where all your messages will appear after you have created them. Please use the button below to
        create your message message that will be dispatched once your accounted is unlocked by your trustees.
      </p>
      <router-link :to="{ name: 'messages.create' }" class="btn btn-primary font-weight-bolder font-size-sm">
        <span class="svg-icon svg-icon-md svg-icon-white">
          <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Add-user.svg-->
          <inline-svg src="media/svg/icons/Communication/urgent-mail.svg" />
          <!--end::Svg Icon--> </span
        >Create Message
      </router-link>
    </div>
    <div class="d-flex justify-content-center flex-wrap">
      <template v-for="(message, i) in messages">
        <div v-bind:key="i" class="card card-custom gutter-b mr-5 mb-b w-300px">
          <div class="card-body px-3 text-center">
            <h3 class="mb-7 text-center">
              <router-link style="color: #212121" :to="{ name: 'messages.single', params: { id: message.ID } }">{{
                message.post_title
              }}</router-link>
            </h3>
            <div class="d-flex justify-content-center flex-wrap">
              <span class="badge bg-light-primary badge-sm mr-2 mb-2">To: </span>
              <span
                v-for="(recipient, i) in message.recipients"
                :key="i"
                class="badge badge-secondary badge-sm mr-2 mb-2"
                >{{ recipient.name }}</span
              >
            </div>
          </div>
          <div class="card-footer">
            <div class="d-flex justify-content-center ">
              <div class="btn btn-sm mr-2 mr-auto" disabled>
                <i
                  class="ml-2 mr-3"
                  :class="
                    message.post_status == 'publish'
                      ? 'fas fa-check-circle text-success'
                      : 'fas fa-pen-square text-warning'
                  "
                ></i
                >{{ message.post_status == "publish" ? "Ready to Send" : "Draft" }}
              </div>
              <router-link
                class="btn btn-icon btn-light btn-sm mr-2"
                :to="{ name: 'messages.single', params: { id: message.ID } }"
              >
                <span class="svg-icon svg-icon-md">
                  <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Write.svg-->
                  <inline-svg src="media/svg/icons/Communication/Write.svg" />
                  <!--end::Svg Icon-->
                </span>
              </router-link>
              <a @click.prevent="deleteMessage(message)" class="btn btn-icon btn-sm btn-light">
                <span class="svg-icon svg-icon-md ">
                  <!--begin::Svg Icon | path:assets/media/svg/icons/General/Trash.svg-->
                  <inline-svg src="media/svg/icons/General/Trash.svg" />
                  <!--end::Svg Icon-->
                </span>
              </a>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<style scoped lang="scss">
.box {
  border: 1px solid #ddd;
}
</style>
<script>
import { mapActions, mapGetters } from "vuex";
import { GET_MESSAGES, DELETE_MESSAGE } from "@/core/services/store/message.module";
import { ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME } from "@/core/services/store/htmlclass.module.js";

import Helpers from "@/core/services/helpers.service.js";
import Swal from "sweetalert2";

export default {
  name: "widget-8",

  components: {},

  data() {
    return {
      checked: false
    };
  },

  computed: {
    ...mapGetters(["messages", "currentUser"]),

    hasMessages() {
      return this.messages && this.messages.length > 0 ? true : false;
    }
  },

  beforeMount() {
    // check if there are messages; if not create a new message
    if (!this.messages || this.messages.length < 1) {
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

      this.$store
        .dispatch(GET_MESSAGES)

        .then(() => {
          if (this.messages < 1) {
            this.$router.push({ name: "messages.create" });
          }
        })

        .catch(error => {
          console.log(error);
        })

        .finally(() => {
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        });
    }
  },

  methods: {
    ...mapActions(["toast"]),

    setCheck(checked) {
      this.checked = checked;
    },

    timeSince(date) {
      return Helpers.timeSince(date);
    },

    deleteMessage(message) {
      Swal.fire({
        text: "Are you sure you want to delete this message?",
        icon: "warning",
        buttonsStyling: false,
        heightAuto: false,
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn font-weight-bold btn-light-primary",
          cancelButton: "btn font-weight-bold btn-default"
        }
      }).then(result => {
        if (result.isConfirmed) {
          this.$store
            .dispatch(DELETE_MESSAGE, message.ID)

            .then(() => {
              const toast_content = `Message: was successfully deleted.`;
              this.toast(toast_content);
            })

            .catch(error => {
              const toast_content = `Error | ${error.message}`;
              this.toast(toast_content);
            });
        }
      });
    }
  }
};
</script>
