//This service provides generic helper functions

const Helpers = {
  timeSince(date) {
    //   make sure the date is correctly pursed
    if (isNaN(date)) date = new Date(date).getTime();

    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
      const s = Math.floor(interval) > 1 ? "s" : "";
      return Math.floor(interval) + " years" + s;
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      const s = Math.floor(interval) > 1 ? "s" : "";
      return Math.floor(interval) + " month" + s;
    }
    interval = seconds / 86400;
    if (interval > 1) {
      const s = Math.floor(interval) > 1 ? "s" : "";
      return Math.floor(interval) + " day" + s;
    }
    interval = seconds / 3600;
    if (interval > 1) {
      const s = Math.floor(interval) > 1 ? "s" : "";
      return Math.floor(interval) + " hour" + s;
    }
    interval = seconds / 60;
    if (interval > 1) {
      const s = Math.floor(interval) > 1 ? "s" : "";
      return Math.floor(interval) + " minute" + s;
    }
    const s = Math.floor(interval) > 1 ? "s" : "";
    return Math.floor(seconds) + " second" + s;
  },

  shorten(text, maxLength = 30) {
    if (text.length > maxLength) {
      text = text.substring(0, maxLength) + "...";
    }

    return text;
  }
};

export default Helpers;
